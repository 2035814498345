import React from 'react';
import styled from 'styled-components';

const StyledOptionLabel = styled.label`
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
`;

interface OptionLabelProps {
  label: string;
  required?: boolean;
}

const OptionLabel: React.FC<OptionLabelProps> = ({ label, required }) => (
  <StyledOptionLabel>
    {label} {required && '*'}
  </StyledOptionLabel>
);

export default OptionLabel;
