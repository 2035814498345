import React from 'react';
import styled from 'styled-components';

const StyledColorOption = styled.div<{ color: string; isSelected: boolean }>`
  width: 30px;
  height: 30px;
  background-color: ${(props) => props.color};
  border-radius: 5px;
  cursor: pointer;
  border: 2px solid ${(props) => (props.isSelected ? '#333' : 'transparent')};
  &:hover {
    border-color: #666;
  }
`;

interface ColorOptionProps {
  color: string;
  isSelected: boolean;
  onClick: () => void;
}

const ColorOption: React.FC<ColorOptionProps> = ({ color, isSelected, onClick }) => (
  <StyledColorOption color={color} isSelected={isSelected} onClick={onClick} />
);

export default ColorOption;
