import * as React from "react";
import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Store } from "redux";
import { IttyniState } from "./store";
import { History } from "history";
import Fallback from "./pages/Fallback";
// import apollo client
import { default as resolvers } from "./store/apolloResolvers";

// import aministration component
import { ThemeProvider } from "styled-components";
import { lightTheme } from "./ui-ittyni/src/theme";
import {
  ApolloClient,
  ApolloProvider,
  from,
  InMemoryCache,
} from "@apollo/client";
import { apiApollo, authMiddleware, errorHandling } from "./store/config";

// dispatcher

// translation
import "./i18n";
import { Home } from "./pages/webApp";
import { AdminApp } from "./pages/adminApp";
import { SpaceApp } from "./pages/spaceApp";

interface MainProps {
  store: Store<IttyniState>;
  history: History;
}

const cache = new InMemoryCache();

const client = new ApolloClient({
  cache,
  link: from([ errorHandling, authMiddleware, apiApollo]),
  resolvers,
  connectToDevTools: true
});
const router = createBrowserRouter([
  {
    path: '/space/:username/:spaceId/*',
    element: <SpaceApp />,
    errorElement: <Fallback />
  },
  {
    path: '/admin/:username/*',
    element: <AdminApp />,
    errorElement: <Fallback />
  },
  {
    path: "/web/*",
    element: <Home />,
    errorElement: <Fallback />
  },
  // Fallback route
  {
    path: "*",
    element: <Fallback />,
  },
]);
const Main: React.FC<MainProps> = ({ store }) => {
  return (
    <ThemeProvider theme={lightTheme}>
      <React.Fragment>
        <ApolloProvider client={client}>
          <Provider store={store}>
            <RouterProvider router={router} />
          </Provider>
        </ApolloProvider>
      </React.Fragment>
    </ThemeProvider>
  );
};

export default Main;
