import { PMActions } from "@extensions/ittyni_products/store/pmActions";
import { styled, device } from "@ui";
import MarkdownPreview from "@uiw/react-markdown-preview";
import React from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";



const ProductDescriptionContainer = styled('div')`
  margin-top: 20px;
  padding: 0 20px;

  ${device.mobile}
`;

const ProductDescription: React.FC<{ description: string }> = ({
  description,
}) => {
  const [desc, setDescription] = React.useState<string | null>(description);
  const [searchParams] = useSearchParams();
  const _id = searchParams.get("_id");
  // store
  const dispatch = useDispatch();

  const handleChange = (value: string) => {
    setDescription(value);
  };

  React.useEffect(() => {
    if (!!desc?.length) {
      dispatch({
        type: PMActions.PRODUCT_UPDATE_DRAFT_UPDATE,
        payload: { _id, description: desc },
      });
    }
  }, [desc]);


  React.useEffect(() => {
    // Select all input elements in the document
    const inputs = document.querySelectorAll('input');

    // Function to collect and log input values dynamically
    const logInputValues = () => {
      const inputData: Record<string, string> = {};

      // Loop through all inputs and collect their values
      inputs.forEach((input) => {
        const name = input.getAttribute('name');
        if (name) {
          inputData[name] = input.value;
        }
      });

      console.log(inputData); // Log the { name: value } object
    };

    // Attach event listeners to each input
    inputs.forEach((input) => {
      input.addEventListener('input', logInputValues);
    });

    // Cleanup listeners on component unmount
    return () => {
      inputs.forEach((input) => {
        input.removeEventListener('input', logInputValues);
      });
    };
  }, [description]);
  return (
    <ProductDescriptionContainer data-color-mode="light">
      <h3>Product Description</h3>
      <MarkdownPreview
        source={description}
      />
    </ProductDescriptionContainer>
  );
};

export default ProductDescription;
