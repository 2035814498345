import { PMActions } from "@extensions/ittyni_products/store/pmActions";
import MarkdownEditor from "@uiw/react-markdown-editor";
import React from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";


const ProductDescriptionContainer = styled.div`
  margin-top: 20px;
  padding: 0 20px;
`;

const ProductDescription: React.FC<{ description: string }> = ({
  description,
}) => {
  const [desc, setDescription] = React.useState<string | null>(description);
  const [searchParams] = useSearchParams();
  const _id = searchParams.get("_id");
  // store
  const dispatch = useDispatch();

  const handleChange = (value: string) => {
    setDescription(value);
  };

  const handleFormSubmit = (event: SubmitEvent) => {
    event.preventDefault(); // Prevent the default form submission behavior
  
    console.log('Form submitted:', event);
  
    // Capture the input value
    const inputElement = (event.target as HTMLFormElement).elements.namedItem('myInput') as HTMLInputElement;
  
    if (inputElement) {
      console.log('Input value:', inputElement.value); // Log the input value
      dispatch({
        type: 'UPDATE_MARKDOWN_INPUT',
        payload: inputElement.value,
      });
  
      inputElement.value = ''; // Clear the input field
    }
  };

  React.useEffect(() => {
    const form = document.getElementById('markdown-form');
    form?.addEventListener('submit', handleFormSubmit);

    return () => {
      form?.removeEventListener('submit', handleFormSubmit);
    };
  }, []);

  React.useEffect(() => {
    if (!!desc?.length) {
      dispatch({
        type: PMActions.PRODUCT_UPDATE_DRAFT_UPDATE,
        payload: { _id, description: desc },
      });
    }
  }, [desc]);
  return (
    <ProductDescriptionContainer>
      <h3>Product Description</h3>
      <MarkdownEditor
        height="400px"
        value={description}
        onChange={(value) => handleChange(value)}
      />
    </ProductDescriptionContainer>
  );
};

export default ProductDescription;
