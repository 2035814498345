import React from 'react';

interface CheckboxInputProps {
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckboxInput: React.FC<CheckboxInputProps> = ({ checked, onChange }) => (
  <input type="checkbox" checked={checked} onChange={onChange} />
);

export default CheckboxInput;
