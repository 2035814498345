import * as React from "react";
import ProductDetailsPage from "./WebProductDetails.v2";
import { Route, Routes } from "react-router-dom";
import OrderCheckout from "./ProductOrder/OrderCheckout";
import ShoppingCart from "./ProductOrder/OrderCart";
import { WebProductList } from "./WebProductList";

export const ROUTE_BASE = "products";

const WebProductDashboard: React.FC<any> = () => {

  return (
    <div style={{ width: "100%" }}>
      <Routes>
        <Route path="product" element={<ProductDetailsPage />} />
        <Route path="cart" element={<ShoppingCart />} />
        <Route path="orderCheckout" element={<OrderCheckout />} />
        <Route path="" element={<WebProductList />} />
      </Routes>
    </div>
  );
};

export default WebProductDashboard;