import { useMutation, useQuery } from "@apollo/client";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ListContainer } from "../../../ui-ittyni";
import {
  ADMIN_USER_READ_ENABLED_EXTENSIONS,
  INSTALL_COMPONENT,
  PUBLIC_USER_WRITE_INSTALL_EXTENSION,
  READ_COMPONENTS,
} from "../controller/userController";
import { useNavigate } from "react-router-dom";
import ModuleGrid from "@core/ittyni_modules/components/GridCard/ModuleGrid";

export const AdminEnableExtension: React.FC<any> = ({}) => {
  const user = useSelector(({ user }: any) => user.userConnected);
  const [fetchedModules, setFetchedModules] = React.useState<any>();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Fetch components data
  const modules = useQuery(READ_COMPONENTS);

  // Mutation to install extensions
  const [installExt, installExtData] = useMutation(INSTALL_COMPONENT);

  // Mutation to activate extensions
  const [activateExt, extensions] = useMutation(
    PUBLIC_USER_WRITE_INSTALL_EXTENSION,
    { context: { clientName: "users" } }
  );

  // Handle fetched components data
  React.useEffect(() => {
    if (modules.loading || !modules.data) return; // Handle loading state

    const cpData = modules.data?.admin_user_read_components;
    if (cpData) {
      setFetchedModules({
        installed: cpData.installed?.map((module: any) => ({
          _id: module._id,
          icon: module.icon,
          title: module.name,
          description: module.description,
          buttonLabel: "Disable",
          onClick: (_id: string) => navigate(`../updateExtension/${_id}`),
        })),
        notInstalled: cpData.notInstalled?.map((module: any) => ({
          _id: module._id,
          icon: module.icon,
          title: module.name,
          description: module.description,
          buttonLabel: installExtData.loading ? "Installing..." : "Install",
          onClick: (_id: string) => installExt({ variables: { _id } }),
        })),
      });
    }
  }, [modules.data, installExtData.loading]); // Added modules.data and installExtData.loading to dependencies

  // Refetch modules when extensions are updated
  React.useEffect(() => {
    if (extensions.data) {
      modules.refetch();
    }
  }, [extensions.data]);

  return (
    <div>
      <h1>Manage Your Extensions</h1>
      <p>
        Vous pouvez ajouter des extensions à votre compte personnel ou votre
        organisme en cliquant sur l'icône d'installation.
      </p>
      <h4>Extensions Installed</h4>
      {modules.loading && <div>Loading modules...</div>}
      {!modules.loading && <ModuleGrid modules={fetchedModules?.installed} />}
      <h4>Not Installed Extensions</h4>
      {!modules.loading && (
        <ModuleGrid modules={fetchedModules?.notInstalled} />
      )}
    </div>
  );
};
