import * as React from "react";
import styled from "styled-components";
import ProductDetailsInfo from "../ProductPage/ProductDetailsInfo.v1";
import ProductImageView from "./ProductImageView";

const ProductDisplayerContainer = styled("div")`
  display: flex;
`;

const ProductDisplayer: React.FC<any> = ({images}) => {

  return (
    <ProductDisplayerContainer>
      <ProductImageView rawImages={images} />
      <ProductDetailsInfo />
    </ProductDisplayerContainer>
  );
};

export default ProductDisplayer;