import React from 'react';
import {
  Card,
  Icon,
  Content,
  Title,
  ModuleInfoLink,
  Actions,
  InstallButton,
} from './ModuleGridStyles';

const ModuleCard = ({ icon, title, description, onClick, _id, buttonLabel, photo }: any) => {
  return (
    <Card>
      {icon&&<Icon svgString={icon} width='45px' height='45px'/>}
      {photo&&<img src={photo} style={{width: "70px", height: "50px"}} />}
      <Content>
        <Title title={title}>{title}</Title>
        {description&&<ModuleInfoLink href="#">{description}</ModuleInfoLink>}
      </Content>
      <Actions>
        {_id&&<InstallButton onClick={()=>onClick(_id)}>{buttonLabel}</InstallButton>}
        {!_id&&<InstallButton onClick={()=>onClick({icon, title, description, photo})}>{buttonLabel}</InstallButton>}
      </Actions>
    </Card>
  );
};

export default ModuleCard;
