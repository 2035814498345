// src/productComponent/ThumbnailSidebar.tsx
import React, { useRef, useEffect, useState } from 'react';
import {styled, device} from '@ui';

const SidebarContainer = styled("div")`
  width: 10)%;
  height: 100%;
  position: relative;

  ${device.mobile`
    
  `}
`;

const ThumbnailWrapper = styled("div")`
  height: calc(100% - 80px);
  overflow-y: scroll;
  padding: 10px 0;
  box-sizing: border-box;

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  scrollbar-width: none;
  -ms-overflow-style: none;

  ${device.mobile`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
  `}
`;

const ThumbnailContainer = styled("div")`
  position: relative;
  margin: 10px 0;
`;

const Thumbnail = styled("img")<any>`
  width: 80%;
  height: 80px;
  cursor: pointer;
  object-fit: cover;
  border: ${(props) =>
    props.isDefault
      ? '2px solid red'
      : props.active
      ? '2px solid #007bff'
      : 'none'};
`;



interface ThumbnailSidebarProps {
  currentIndex: number;
  defaultImageIndex: number;
  onThumbnailClick: (index: number) => () => void;
  onAddThumbnailClick: () => void;
  onCancel: () => void;
  onDeleteThumbnail: (index: number) => void;
  images: string[]; // Add this prop to accept images
  onFilesSelected: (files: string[]) => void; // Callback to handle file selection
}

const ThumbnailSidebar: React.FC<ThumbnailSidebarProps> = ({
  currentIndex,
  defaultImageIndex,
  onThumbnailClick,
  images,
  onFilesSelected,
}) => {
 
  const thumbnailRefs = useRef<(HTMLImageElement | null)[]>([]);

  useEffect(() => {
    if (thumbnailRefs.current[currentIndex]) {
      thumbnailRefs.current[currentIndex]?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [currentIndex]);

  return (
    <SidebarContainer>
      <ThumbnailWrapper>
        {images.map((src, index) => (
          <ThumbnailContainer
            key={index}
            onMouseEnter={() => console.log("1")}
            onMouseLeave={() => console.log("2")}
          >
            <Thumbnail
              src={src}
              alt={`Thumbnail ${index + 1}`}
              onClick={onThumbnailClick(index)}
              active={currentIndex === index}
              isDefault={index === defaultImageIndex}
              ref={(el: any) => (thumbnailRefs.current[index] = el)}
            />
          </ThumbnailContainer>
        ))}
      </ThumbnailWrapper>

    </SidebarContainer>
  );
};

export default ThumbnailSidebar;