import React from "react"; 
import { Link } from "react-router-dom";
import styled from "styled-components";

// Styled component for the wrapper
const NotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #f8f9fa;
  padding: 20px;

  h1 {
    font-size: 2rem;
    margin-bottom: 16px;
    color: #343a40;
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: #6c757d;
  }

  button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;

    &:hover {
      background-color: #0056b3;
    }
  }

  img {
    max-width: 400px;
    width: 100%;
    height: auto;
    margin: 20px 0;
    border-radius: 10px;
  }
`;

// Fallback Component
const Fallback: React.FC = () => {
  return (
    <NotFoundWrapper>
      <h1>Page Not Found</h1>
      <p>
        We are working hard to release this website as soon as possible. Stay tuned!
      </p>
      <img src="/fallback.gif" alt="Website Coming Soon" />
      <p>Sorry, the page you are looking for does not exist.</p>
      <Link to="/web/products">
        <button>Go to Products</button>
      </Link>
    </NotFoundWrapper>
  );
};

export default Fallback;

