import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import {styled, device} from "@ui";
import { WEB_READ_PRODUCT_BY_ID } from "../../controller/pmController";
import { useSearchParams } from "react-router-dom";
import { PMActions } from "../../store/pmActions";
import { useDispatch } from "react-redux";
import ProductOptions from "../ProductOptions/ProductOptions";

const Container = styled.div`
  width: 30%;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  font-family: Arial, sans-serif;
  margin: 0 15px;
  ${device.mobile`
    width: 95%;
    border: 1px solid rgb(221, 221, 221);
    margin: 0px 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  `}
`;

const Header = styled.div`
  background-color: #ff4b4b;
  color: white;
  text-align: center;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
`;

const PriceSection = styled.div`
  padding: 10px;
  font-size: 24px;
  color: #ff4b4b;
  text-align: left;
  span {
    font-size: 21px !important;
    text-align: left;
  }
`;

const Title = styled.div`
  padding: 10px;
  font-size: 16px;
  color: #333;
  text-align: left;
`;

const ProductDetailsInfo = () => {
  const [headerText, setHeaderText] = useState<string>("Nouveau Arrivee");
  const [product, setProduct] = useState<Product | null>(null);
  const [productUpdate, setProductUpdate] = useState<any>(null)
  const [searchParams] = useSearchParams();
  const _id = searchParams.get("_id");

  // store
  const dispatch = useDispatch();

  const [readProduct, { data, loading, error }] = useLazyQuery(WEB_READ_PRODUCT_BY_ID);

  useEffect(() => {
    if (_id) {
      dispatch({type: PMActions.PRODUCT_UPDATE_DRAFT_START, payload: {_id}})
      readProduct({ variables: { _id } });
    }
  }, [_id, readProduct]);

  useEffect(() => {
    if (data?.public_product_read_productById) {
      setProduct(data.public_product_read_productById);
    }
  }, [data]);

  React.useEffect(() => {
    if(productUpdate){
      dispatch({type: PMActions.PRODUCT_UPDATE_DRAFT_UPDATE, payload: {_id, ...productUpdate}})
    }
  }, [productUpdate])

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

 
  return (
    <Container>
      <Header>
        {headerText}
      </Header>

      <PriceSection>
        <span>{product?.price?.currency || "MAD"}</span>
        <span>{product?.price?.value?.toString() || "9.97"}</span>         
      </PriceSection>
      
      <Title>
        {product?.title || "Product Title Here"}
      </Title>

      <ProductOptions opt={product?.options}/>
    </Container>
  );
};

export default ProductDetailsInfo;