import React, { useState, useEffect } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  SPACE_WRITE_PRODUCT_TITLE,
  ADMIN_UPDATE_PRODUCT,
} from "../controller/pmController";
import { Bubble } from "../../../ui-ittyni";
import { Link, useLocation } from "react-router-dom";
import { ProductSuplier } from "../components/ProductSuplier";
import ProductDetailsPage from "../web/WebProductDetails.v2";
import { ProductBrand } from "../components/ProductBrand";
import { ProductInfos } from "../components/ProductInfos";
import { useDispatch } from "react-redux";
import { PMActions } from "../store/pmActions";
import FileUpload from "../space/SpaceProductImages";

interface ProductInput {
  name: string;
  description: string;
  price: number;
  categories: string[];
  stockQuantity: number;
  images: string[];
  [key: string]: any;
}

export const AddProduct: React.FC = () => {
  const [product, setProduct] = useState<ProductInput>({
    name: "",
    description: "",
    price: 0,
    categories: [],
    stockQuantity: 0,
    images: [],
  });
  const [updateID, setIDToUpdate] = useState<string | undefined>();
  const [addProduct, { loading: adding, error: addError }] = useMutation<{
    addProduct: ProductInput;
  }>(SPACE_WRITE_PRODUCT_TITLE, {
    context: { clientName: "product" },
    onCompleted: () => {},
  });
  const [updateProduct, { loading: updating, error: updateError }] =
    useMutation(ADMIN_UPDATE_PRODUCT, {
      context: { clientName: "product" },
    });


  const { hash, search } = useLocation();
  const dispatch = useDispatch();

;

  const handleSave = async () => {
    if (!updateID) {
      try {
        const { data } = await addProduct({
          variables: {
            product: {
              ...product,
              categories: product.categories.map((c: any) => c._id),
              images: product.images.map((i: any) => i._id),
            },
          },
        });

        if (data) {
          dispatch({
            type: PMActions.PRODUCT_ADD_TO_LIST,
            payload: data,
          });
        }
      } catch (error) {
        console.error("Error adding product:", error);
      }
    } else {
      try {
        delete product.__typename;
        await updateProduct({
          variables: {
            product: {
              ...product,
              price: product.price.toString(),
              categories: product.categories.map((c: any) => c._id),
              images: product.images.map((i: any) => i._id),
            },
          },
        });
      } catch (error) {
        console.error("Error updating product:", error);
      }
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <h2>{updateID ? "Update Product" : "Add Product"}</h2>
      <div style={{ display: "flex" }}>
        <Bubble isSelected={!hash || hash === ""} selectedBgColor="#C5CAE9">
          <Link to="#general">General</Link>
        </Bubble>
        <Bubble>
          <Link to={"#images"}>upload images</Link>
        </Bubble>
        <Bubble isSelected={hash === "#details"} selectedBgColor="#C5CAE9">
          <Link to="#details">Product Details</Link>
        </Bubble>
        <Bubble isSelected={hash === "#brand"} selectedBgColor="#C5CAE9">
          <Link to="#brand">Brand Details</Link>
        </Bubble>
        <Bubble isSelected={hash === "#suplier"} selectedBgColor="#C5CAE9">
          <Link to="#suplier">Supplier Details</Link>
        </Bubble>
      </div>

      {hash === "#images" && (
        <FileUpload
          uploadedFilesData={[
            {
              _id: "34324324",
              filename: "string",
              originName: "image1",
              extension: "jpg",
            },
          ]}
          setFilesUploadedData={(d: any) => console.log(d)}
        />
      )}
      {hash === "#brand" && (
        <ProductBrand product={product} setProduct={setProduct} />
      )}
      {hash === "#suplier" && (
        <ProductSuplier product={product} setProduct={setProduct} />
      )}
      {(!hash || hash === "#general") && (
        <ProductInfos product={product} setProduct={setProduct} />
      )}
      <button onClick={handleSave} disabled={adding || updating}>
        {adding || updating
          ? "Saving..."
          : updateID
          ? "Update Product"
          : "Add Product"}
      </button>
      {(addError || updateError) && (
        <p>Error: {addError?.message || updateError?.message}</p>
      )}
    </div>
  );
};
