import React, { useState } from 'react';
import OptionLabel from './components/OptionLabel';
import TextInput from './components/TextInput';
import CheckboxInput from './components/CheckboxInput';
import SelectOption from './components/SelectOption';
import RadioOption from './components/RadioOption';
import ColorOption from './components/ColorOption';

interface OptionValue {
  label: string;
  value: any
}

interface ProductOption {
  name: string;
  type: 'select' | 'radio' | 'color' | 'text' | 'number' | 'boolean';
  values?: OptionValue[];
  required: boolean;
}

interface ProductOptionsDisplayerProps {
  options: ProductOption[];
  onOptionChange: (
    optionName: string,
    value: string | number | boolean
  ) => void;
}

const ProductOptionsDisplayer: React.FC<ProductOptionsDisplayerProps> = ({
  options,
  onOptionChange,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<
    Record<string, string | number | boolean>
  >({});

  const handleOptionChange = (
    optionName: string,
    value: string | number | boolean
  ) => {
    setSelectedOptions((prev) => ({ ...prev, [optionName]: value }));
    onOptionChange(optionName, value);
  };

  return (
    <div>
      {options.map((option) => (
        <div key={option.name}>
          <OptionLabel label={option.name} required={option.required} />
          {option.type === 'select' && option.values && (
            <SelectOption
              options={option.values}
              value={selectedOptions[option.name]}
              onChange={(e) => handleOptionChange(option.name, e.target.value)}
            />
          )}
          {option.type === 'radio' && option.values && (
            <RadioOption
              name={option.name}
              options={option.values}
              selectedValue={selectedOptions[option.name]}
              onChange={(value) => handleOptionChange(option.name, value)}
            />
          )}
          {option.type === 'color' && option.values && (
            <div style={{ display: 'flex', gap: '10px' }}>
              {option.values.map((value) => (
                <ColorOption
                  key={value.value.toString()}
                  color={value.value || '#fff'}
                  isSelected={selectedOptions[option.name] === value.value}
                  onClick={() => handleOptionChange(option.name, value.value)}
                />
              ))}
            </div>
          )}
          {option.type === 'text' && (
            <TextInput
              type="text"
              value={(selectedOptions[option.name] as string) || ''}
              onChange={(e) => handleOptionChange(option.name, e.target.value)}
            />
          )}
          {option.type === 'number' && (
            <TextInput
              type="number"
              value={(selectedOptions[option.name] as number) || ''}
              onChange={(e) =>
                handleOptionChange(option.name, Number(e.target.value))
              }
            />
          )}
          {option.type === 'boolean' && (
            <CheckboxInput
              checked={Boolean(selectedOptions[option.name])}
              onChange={(e) =>
                handleOptionChange(option.name, e.target.checked)
              }
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default ProductOptionsDisplayer;