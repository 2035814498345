import React from 'react';
import { styled } from "@ui";

// Styled Components
const Container = styled.div`
  padding: 2px;
  border-bottom: 1px solid #ddd;
  font-family: Arial, sans-serif;
`;

const Title = styled('div')`
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
`;

const CommitmentList = styled.ul`
  list-style: none;
  padding: 5px;
  margin: 0;
`;

const CommitmentItem = styled.li`
  display: flex;
  align-items: flex-start;
  padding: 12px;
  margin: 10px 0;
  background-color: #fff;
  border-radius: 8px;
`;

const IconWrapper = styled.div`
  font-size: 1.3rem;
  margin-right: 15px;
  color: #4caf50; /* Default green for free services */
`;

const TextWrapper = styled.div`
  flex: 1;
`;

const PolicyName = styled.h4`
  font-size: 1.1rem;
  color: #0073e6;
  margin: 0 0 5px;
  font-weight: 600;
`;

const Description = styled.p`
  color: #555;
  font-size: 0.9rem;
  margin: 0;
`;

const Duration = styled.span`
  font-size: 0.85rem;
  color: #888;
  font-weight: bold;
`;

const Commitment = ({ commitments }: { commitments: Commitment[] }) => {
  return (
    <Container>
      <Title>Commitments</Title>
      <CommitmentList>
        {commitments.map((commitment, index) => (
          <CommitmentItem key={index}>
            <IconWrapper>
              {commitment.policyName === 'Free Returns' && (
                <i className="fa fa-undo" />
              )}
              {commitment.policyName === '15-Day Trial' && (
                <i className="fa fa-clock" />
              )}
              {commitment.policyName === '1-Year Warranty' && (
                <i className="fa fa-shield-alt" />
              )}
            </IconWrapper>
            <TextWrapper>
              <PolicyName>{commitment.policyName}</PolicyName>
              <Description>{commitment.description}</Description>
              {commitment.duration && (
                <Duration>{` - ${commitment.duration} days`}</Duration>
              )}
            </TextWrapper>
          </CommitmentItem>
        ))}
      </CommitmentList>
    </Container>
  );
};

export default Commitment;