import { device } from '@ui';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// Atoms
const Button = styled.button<{ left?: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  padding: 5px;
  cursor: pointer;
  ${(props) => (props.left ? 'left: 10px;' : 'right: 10px;')}
`;

const ProductImage = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
`;

const Star = styled.i<{ active: boolean }>`
  color: ${(props) => (props.active ? '#FFD700' : '#ccc')};
  margin-right: 2px;
`;

const Price = styled.div`
  font-size: 20px;
  color: #ff5722;
  font-weight: bold;
  margin-top: 10px;
  padding: 5px;
  border-radius: 5px;
`;

// Molecules
const ProductRank = ({
  rank,
  ratingCount,
}: {
  rank: number;
  ratingCount: number;
}) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    {[...Array(5)].map((_, i) => (
      <Star key={i} className="fas fa-star" active={i < rank} />
    ))}
    <span style={{ fontSize: '14px', color: '#777', marginLeft: '8px' }}>
      ({ratingCount})
    </span>
  </div>
);

const SalesCount = styled.div`
  font-size: 16px;
  color: #48814a;
  font-weight: bold;
  margin-top: 8px;
  padding: 4px 8px;
  background-color: #e8f5e9;
  border-radius: 12px;
  display: inline-block;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

// Organisms (Full Product Card)
const Card = styled.div`
  width: 100%;
  max-width: 200px;
  margin: 5px;
  overflow: hidden;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #fff;

  ${device.mobile`
    max-width: 140px;
  `}

  &:hover {
    transform: translateY(-5px);
  }

`;

const ProductInfo = styled.div`
  padding: 10px;
`;

const ProductName = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin: 5px 0;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 150px;
`;

const ProductCard = ({ _id, images, name, price, rank, ratingCount }: any) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <Card>
      <ImageContainer>
        <ProductImage src={images[currentImageIndex]} alt={name} />
        <Button left onClick={handlePrevImage}>
          ❮
        </Button>
        <Button onClick={handleNextImage}>❯</Button>
      </ImageContainer>
      <ProductInfo>
        <ProductName>
          <Link to={`product?_id=${_id}`}>{name}</Link>
        </ProductName>
        <ProductRank rank={rank} ratingCount={ratingCount} />
        <SalesCount>Sales: {100}</SalesCount>
        <Price>{price}</Price>
      </ProductInfo>
    </Card>
  );
};

export default ProductCard;