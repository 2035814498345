import React from "react";
import styled from "styled-components";
import ProductDisplayer from "../components/ProductDisplayer/ProductDisplayer";
import ProductInfo from "../components/ProductPage/ProductInfo"
import { useLazyQuery, useMutation } from "@apollo/client";
import { ADMIN_READ_PRODUCT_BY_ID, ADMIN_UPDATE_PRODUCT } from "../controller/pmController";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { imageService } from "src/store/config";


const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
`;
const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`;
const DetailsSidebar = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
`;
const ContainerAction = styled.div`
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  font-family: Arial, sans-serif;
  margin: 0 15px;
  text-align: left;
`;

const AdminProductDetails = () => {
  // read data from state
  const data = useSelector(({ products }: any) => products)
  // get _id
  const [searchParams] = useSearchParams();
  const _id = searchParams.get("_id");

  // read exist data of product
  const [readProduct, { data: productData, loading, error, refetch }] = useLazyQuery(ADMIN_READ_PRODUCT_BY_ID);

  // load mutation update
  const [updateProduct, { loading: updating }] = useMutation(ADMIN_UPDATE_PRODUCT, {onCompleted: refetch});

  const saveUpdates = () => {
    if (!_id) return;
    const images = data[_id]?.images || [];
    const imageIds = images.map((image: any) => image._id).filter(Boolean);
    let product = {};
    if (!!imageIds?.length){
      product = {images: [...imageIds]}
    }
    if(!!data[_id]?.options?.length){
      product = {...product, options: data[_id]?.options}
    }
    if(data[_id]?.price){
      product = {...product, price: {value: parseFloat(data[_id]?.price.value), currency: data[_id]?.price.currency ?? "MAD"}}
    }
    if(data[_id]?.description){
      product = {...product, description: data[_id]?.description}
    }
    if(data[_id]?.title){
      product = {...product, title: data[_id]?.title}
    }
    updateProduct({ variables: { _id, product } })
  }

  React.useEffect(()=>{
    readProduct({variables: {_id}})
  }, [_id])

  const productImages = productData?.admin_product_read_productById?.images?.map(
    (i: any) => `${imageService}/${i.filename}`
  );
  return (

    <Container>
      <MainContent>
        <ProductDisplayer images={productImages} />
        <ProductInfo
          avatarUrl="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpmlKSkUcFRuSba3I8DHnEsEHdQNgP5KTSPw&s"
          storeName="Essalhi key Programmer"
          storeSells="1.3k sells"
          likes="2.4k"
          dislikes="5"
          productDescription="Key for hyandai 2012"
        />
      </MainContent>
      <DetailsSidebar>
        <ContainerAction>
          <button onClick={() => saveUpdates()}>Save Change</button>
        </ContainerAction>
      </DetailsSidebar>
    </Container>
  )
};

export default AdminProductDetails;