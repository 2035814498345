import React from 'react';
import styled from 'styled-components';

const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;

  input {
    margin-right: 8px;
  }
`;

interface RadioOptionProps {
  name: string;
  options: { label: string; value: string | number }[];
  selectedValue: any;
  onChange: (value: string | number) => void;
}

const RadioOption: React.FC<RadioOptionProps> = ({
  name,
  options,
  selectedValue,
  onChange,
}) => (
  <RadioContainer>
    {options.map((option) => (
      <RadioLabel key={option.value.toString()}>
        <input
          type="radio"
          name={name}
          value={option.value.toString()}
          checked={selectedValue === option.value}
          onChange={() => onChange(option.value)}
        />
        {option.label}
      </RadioLabel>
    ))}
  </RadioContainer>
);

export default RadioOption;