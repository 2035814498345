import React, { useState, useCallback } from 'react';
import ThumbnailSidebar from './ThumbnailSidebar';
import Slider from './Slider';
import { device, styled } from '@ui';

const ImageViewWrapper = styled("div")`
  display: flex;
  flex-direction: row;
  width: 80%;
  ${device.mobile`
    flex-direction: column;
    width: 99%;
  `}
`;

const ThumbnailsContainer = styled("div")`
  order: 0;
  
  ${device.mobile`
    order: 2; 
  `}
`;

const SliderContainer = styled("div")`
  flex: 1;
`;
interface ImageViewProps {
  rawImages: string[];
  sliderWidth?: string;
  sliderHeight?: string;
}

const ProductImageView: React.FC<ImageViewProps> = ({
  rawImages,
  sliderWidth = '40%',
  sliderHeight = '500px',
}) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [defaultImageIndex, setDefaultImageIndex] = useState<number>(0);
  const [images, setImages] = useState<string[]>(rawImages || []);

  const nextImage = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images?.length);
  }, [images?.length]);

  const prevImage = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images?.length - 1 : prevIndex - 1
    );
  }, [images?.length]);

  const handleThumbnailClick = useCallback(
    (index: number) => () => {
      setCurrentIndex(index);
    },
    []
  );

  const handleSetDefault = (index: number) => {
    setDefaultImageIndex(index);
  };

  const handleDeleteThumbnail = (index: number) => {
    setImages(images.filter((_, i) => i !== index));
    if (index === currentIndex) {
      setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
    }
    if (index === defaultImageIndex) {
      setDefaultImageIndex(0);
    }
  };

  const handleFilesSelected = (newFiles: string[]) => {
    setImages((prevImages) => [...prevImages, ...newFiles]);
  };

  const handleCancel = () => {
    setImages([]); // Clear all images
    setCurrentIndex(0); // Reset the current index
    setDefaultImageIndex(0); // Reset the default image index
  };

  React.useEffect(()=>{
    setImages(rawImages)
  }, [rawImages])
  return (
    <ImageViewWrapper className='imageViewWrapper'>
      <ThumbnailsContainer className='ThumbnailsContainer'>
        <ThumbnailSidebar
          currentIndex={currentIndex}
          onThumbnailClick={handleThumbnailClick}
          onAddThumbnailClick={() => console.log('clicked')}
          defaultImageIndex={defaultImageIndex}
          onDeleteThumbnail={handleDeleteThumbnail}
          images={images}
          onFilesSelected={handleFilesSelected}
          onCancel={handleCancel}
        />
      </ThumbnailsContainer>
      <SliderContainer className='SliderContainer'>
        <Slider
          images={images}
          currentIndex={currentIndex}
          onNext={nextImage}
          onPrev={prevImage}
          defaultImageIndex={defaultImageIndex}
          onSetDefault={handleSetDefault}
        />
      </SliderContainer>
    </ImageViewWrapper>
  );
};

export default ProductImageView;
