import * as React from "react";
import fallbackImage from "../../../docs/fallback.png";
import { imageService, uploadService } from "src/store/config";

export const CategoryCard: React.FC<{
  category: Category;
  isSelected?: boolean;
  onSelect?: () => void;
  onRemove?: () => void;
}> = ({ category, isSelected, onSelect, onRemove }) => {
  const imageUrl = category.icon?.filename && category.icon?.extension
    ? `${imageService}/${category.icon.filename}`
    : fallbackImage;

  return (
    <div
      style={{
        width: "150px",
        height: "30px",
        borderRadius: "20px",
        display: "flex",
        alignItems: "center",
        margin: "0 5px 5px",
        background: isSelected ? "#ffeb3b" : "#f1f1f1",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        cursor: "pointer",
        transition: "box-shadow 0.2s",
        overflow: "hidden", // Ensure contents stay within rounded corners
      }}
      onClick={onSelect}
    >
      {/* Left section: Image */}
      <div
        style={{
          width: "40px",
          height: "100%",
          backgroundColor: "#aaa", // Gray background for the image section
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={imageUrl}
          onError={(e) => (e.currentTarget.src = fallbackImage)}
          alt={category.name}
          style={{
            width: "40px",
            height: "30px",
            objectFit: "cover",
            borderRadius: "5px",
          }}
        />
      </div>

      {/* Middle section: Category label */}
      <span
        style={{
          flex: 1,
          paddingLeft: "5px",
          fontSize: "14px",
          fontWeight: "bold",
          color: "#555",
        }}
      >
        {category.name}
      </span>

      {/* Right section: Close icon */}
      {onRemove && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            onRemove();
          }}
          style={{
            width: "40px",
            height: "100%",
            backgroundColor: "red",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontSize: "16px",
            cursor: "pointer",
          }}
        >
          ✕
        </div>
      )}
    </div>
  );
};