import React, { useEffect, useState } from "react";
import NewProductsHandler from "./NewProductsHandler";
import ExistingProductsHandler from "./ExistingProductHandler";
import { useMutation, useQuery } from "@apollo/client";
import {
  ADMIN_READ_PRODUCTS,
  ADMIN_UPDATE_PRODUCT,
  ADMIN_WRITE_PRODUCT,
} from "@extensions/ittyni_products/controller/pmController";


const ProductsManager: React.FC = () => {
  const { data, loading, error, refetch } = useQuery(ADMIN_READ_PRODUCTS);
  const [products, setProducts] = useState<Product[]>([]); // State to hold the product list

  const [updateProduct, {loading:updating}] = useMutation(ADMIN_UPDATE_PRODUCT, {
    onCompleted: () => refetch(),
  });

  const [writeProduct, {loading: creating}] = useMutation(ADMIN_WRITE_PRODUCT, {
    onCompleted: () => refetch(),
  });

  // Sort products by creation date (newest first) whenever new data arrives
  useEffect(() => {
    if (data?.admin_product_read_Products) {
      const sortedProducts = [...data.admin_product_read_Products].sort(
        (a: Product, b: Product) =>
          new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime()
      );
      setProducts(sortedProducts);
    }
  }, [data]);

  const handleSaveNewProduct = async (
    productId: string | number,
    changes: Partial<Product>
  ) => {

    const { quantity, unit, currency, value }: any = changes;

    const product: Product = {
      _id: productId,
      title: changes.title,
      barcode: changes.barcode,
      stock: {
        quantity: quantity ?? 0,
        unit: unit ?? "unit",
      },
      categories: changes.categories,
      price: {
        currency: currency ?? "MAD",
        value: parseFloat(value) ?? 0,
      }
    };

    try {
      const { _id, ...productWithoutId } = product;
      await writeProduct({
        variables: {
          product: {
            ...productWithoutId,
            categories: product.categories?.map((ct: Category) => ct._id),
          },
        },
      });
    } catch (error: any) {
      alert(error.message || "Failed to create product.");
    }
  };

  const handleSaveProductChanges = async (
    _id: string | number,
    changes: Partial<Product>
  ) => {
    try {
      const reflectedChanges = {
        ...changes,
        categories: changes.categories?.map((ct: Category) => ct._id),
      };

      await updateProduct({
        variables: { _id, product: reflectedChanges },
      });
    } catch (error: any) {
      alert(error.message || "Failed to update product.");
    }
  };

  if (loading || creating || updating) return <p>Loading products...</p>;
  if (error) return <p>Error loading products: {error.message}</p>;

  return (
    <div>
      <h2>New Products</h2>
      <NewProductsHandler onSave={handleSaveNewProduct} loading={loading} />

      <h2>Existing Products</h2>
      <ExistingProductsHandler
        products={products}
        onSaveProductChanges={handleSaveProductChanges}
      />
    </div>
  );
};

export default ProductsManager;

