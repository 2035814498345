import * as React from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import { Panel } from "../../../ui-ittyni";
import { useTranslation } from "react-i18next";
import { SpaceIdentification } from "../components/SpaceIdentification";
import {
  ADMIN_SPACE_READ_ENABLEDANDNOTENABLEDSPACES,
  ENABLE_SPACE,
  READ_SPACES,
  WRITE_SPACE,
} from "../controller/spaceController";
import ModuleGrid from "@core/ittyni_modules/components/GridCard/ModuleGrid";

export const AdminSpaceRegister: React.FC<any> = ({ }) => {
  const [accountData, setAccountData] = React.useState<any>();
  const [existedSpaces, setExistedSpaces] = React.useState<any>();
  const [activatedSpaces, setActivatedSpaces] = React.useState<any>();

  // translation
  const { t } = useTranslation();
  // data requests
  const readSpaces = useQuery(ADMIN_SPACE_READ_ENABLEDANDNOTENABLEDSPACES);
  const [writeSpace, writeSpaceData] = useMutation(WRITE_SPACE, { onCompleted: readSpaces.refetch });
  const [enableSpace, enableSpaceData] = useMutation(ENABLE_SPACE, {
    onCompleted: () => {
      readSpaces.refetch(); // Refetch spaces after enabling a space
    },
  });

  React.useEffect(() => {
    const spacesfetched = readSpaces?.data?.admin_space_read_EnabledAndNotEnabledSpaces;
    if (spacesfetched) {
      setExistedSpaces(
        spacesfetched.registeredSpaces?.map((module: any) => ({
          _id: module._id,
          photo: module.icon,
          title: module.name,
          description: module.description,
          buttonLabel: "I own this business",
          onClick: (_id: string) => enableSpace({ variables: { _id } }),
        }))
      );
    }
  }, [readSpaces?.data]);

  React.useEffect(() => {
    const spacesfetched = readSpaces?.data?.admin_space_read_EnabledAndNotEnabledSpaces;
    if (spacesfetched) {
      setActivatedSpaces(
        spacesfetched.enabledSpaces?.map((module: any) => ({
          _id: module._id,
          photo: module.icon,
          title: module.name,
          description: module.description,
          buttonLabel: "Not Confirmed",
          onClick: (_id: string) => console.log({ variables: { _id } }),
        }))
      );
    }
  }, [readSpaces?.data]);

  if (readSpaces.loading) return <div>loading...</div>;
  return (
    <div style={{ width: "100%" }}>
      <h1>{t("space.title")}</h1>
      <p style={{ marginBottom: "45px" }}>
        Vous pouvez ajouter votre espace de travail pour gerer et manager
      </p>

      <Panel
        Header={
          <div>
            <h2>{t("space.subscribedSpaces")}</h2>
          </div>
        }
      >
        <>
          {!activatedSpaces && <span
            dangerouslySetInnerHTML={{ __html: t("space.noSpaceSubscription") }}
          />}
          {activatedSpaces && <ModuleGrid modules={activatedSpaces} />}
        </>
      </Panel>
      <Panel
        Header={
          <div>
            <h2>{t("space.addSpace")}</h2>
          </div>
        }
      >
        <div>
          <SpaceIdentification
            spaceInformation={accountData}
            setSpaceInformation={setAccountData}
          />
        </div>
      </Panel>
      <ModuleGrid
        modules={
          accountData?.space
            ? [
              {
                photo: accountData?.space.icon,
                title: accountData?.space.name,
                buttonLabel: t("space.activateSpace"),
                onClick: () =>{
                  writeSpace({
                    variables: {
                      space: {
                        photos: accountData?.space.photos,
                        name: accountData?.space.name,
                        icon: accountData?.space.icon,
                        rating: accountData?.space.rating,
                        user_ratings_total:
                          accountData?.space.user_ratings_total,
                        location: accountData?.space.location,
                        reference: accountData?.space.reference,
                      },
                    },
                  })

                  setAccountData({})
                },
              },
            ]
            : []
        }
      />

      <Panel
        Header={
          <div>
            <h2>{t("space.installedSpaces")}</h2>
          </div>
        }
      >
        <ModuleGrid modules={existedSpaces} />
      </Panel>
    </div>
  );
};
