import { AnyAction } from "redux";
import { PMActions } from "./pmActions";

interface State {
  list: any[];
  new: any | null;
  [key: string]: any; // Allows for additional dynamic keys
}
// Define the initial state
const initialState: State = {
  list: [],      // Initialize list as an empty array
  new: null,     // Initialize new as null (or provide a default structure)
};

export const PMReducer = (state = initialState, action: AnyAction) => {
  const { _id, images, ...rest } = action.payload || {};

  switch (action.type) {
    case PMActions.PRODUCT_ADD_TO_LIST:
      return {
        ...state,
        list: state.list ? [...state.list, action.payload] : [action.payload],
      };

    case PMActions.PRODUCT_CREATE_NEW_PRODUCT:
      return {
        ...state,
        new: action.payload,
      };

    case PMActions.PRODUCT_UPDATE_DRAFT_START:
      return {
        ...state,
        [_id]: {_id},
      };

    case PMActions.PRODUCT_UPDATE_DRAFT_UPDATE:
      if (!state[_id]) return state;
      return {
        ...state,
        [_id]: {
          ...state[_id],
          ...rest,
        },
      };

    case PMActions.PRODUCT_UPDATE_DRAFT_IMAGES:
      if (!state[_id]) return state;

      const updatedImages = images
        ? images.map((img: any, index: number) =>
            index === 0 ? { ...img, isDefault: true } : img
          )
        : undefined;

      return {
        ...state,
        [_id]: {
          ...state[_id],
          images: updatedImages,
        },
      };

    default:
      return state; // Return state directly if no matching action type
  }
};

export default PMReducer;