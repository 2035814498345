// src/productComponent/ProductImageUploader.tsx
import React, { useRef, useState } from 'react';
import { useMutation, ApolloClient, InMemoryCache } from '@apollo/client';
import gql from 'graphql-tag';
import { createUploadLink } from 'apollo-upload-client';
import styled, { keyframes } from 'styled-components';
import { PMActions } from '@extensions/ittyni_products/store/pmActions';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { uploadService } from 'src/store/config';

const uploadLink = createUploadLink({
  uri: uploadService,
});
const client = new ApolloClient({
  link: uploadLink,
  cache: new InMemoryCache(),
});

// Define the mutation for multi-file upload
const MULTI_FILE_UPLOAD = gql`
  mutation multiFileUpload($files: [FileUploadRequest!]!) {
    multiFileUpload(files: $files) {
      filename
      originName
      extension
      error
      _id
    }
  }
`;

// Skeleton loading animation
const loadingAnimation = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

// Styled skeleton loader
const SkeletonLoader = styled("div")`
  width: 100%;
  height: 100%;
  background-color: #e0e0e0;
  background-image: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200px 100%;
  animation: ${loadingAnimation} 1.2s ease-in-out infinite;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const Container = styled("div")`
  position: absolute;
  bottom: 0;
  width: 80%;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid #007bff;
  background-color: #e9f7ff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  color: #007bff;
`;

const ButtonsContainer = styled("div")`
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: center;
  flex-direction: column;
`;

const Button = styled("button")<{ cancel?: boolean }>`
  margin: 2px;
  font-size: 12px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  background-color: ${(props) => (props.cancel ? '#dc3545' : '#007bff')};
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: ${(props) => (props.cancel ? '#c82333' : '#0056b3')};
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }
`;

const PlusIcon = styled("div")`
  font-size: 32px;
  color: #007bff;
  margin-bottom: 4px;
`;

const AddText = styled("div")`
  font-size: 16px;
  font-weight: bold;
  color: #007bff;
  text-align: center;
`;


interface ProductImageUploaderProps {
  onFilesSelected: (files: File[]) => void;
  onConfirm: () => void;
  onCancel: () => void;
}

const ProductImageUploader: React.FC<ProductImageUploaderProps> = ({
  onFilesSelected,
  onConfirm,
  onCancel,
}) => {
  const [imagesUploaded, setImagesUploaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [success, setSuccess] = useState(false);
  const [searchParams] = useSearchParams();
  const _id = searchParams.get("_id");

  // store
  const dispatch = useDispatch();

  // Use the mutation hook
  const [multiFileUpload, multiFilesUploaded] = useMutation(MULTI_FILE_UPLOAD, { client });

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const isImageFile = (file: File) => {
    const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
    return file && acceptedImageTypes.includes(file.type);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const validFiles = Array.from(files).filter(isImageFile);
      if (validFiles.length > 0) {
        onFilesSelected(validFiles);
        setImagesUploaded(true);
      } else {
        alert('Please select only image files.');
      }
    }
  };

  const handleConfirm = async () => {
    setIsLoading(true);
    try {
      const filesArray = Array.from(fileInputRef.current?.files || []);
      const files = filesArray.map((file) => ({
        file,
      }));
      await multiFileUpload({
        variables: {
          files,
        },
      });
      setSuccess(true);
      onConfirm();
    } catch (error) {
      console.error('Error uploading files:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setImagesUploaded(false); // Reset imagesUploaded to false
    onCancel(); // Call the passed onCancel function to handle additional cleanup
  };

  React.useEffect(() => {
    let imagesResponse = multiFilesUploaded.data?.multiFileUpload
    if(imagesResponse){
      dispatch({type:PMActions.PRODUCT_UPDATE_DRAFT_IMAGES, payload: {_id, images: imagesResponse}})
    }
  }, [multiFilesUploaded.data, _id])

  return (
    <>
      <Container
        onClick={!imagesUploaded ? handleClick : undefined}
        style={{ backgroundColor: success ? '#28a745' : '#e9f7ff' }} // Update background color on success
      >
        {isLoading ? (
          <SkeletonLoader />
        ) : success ? (
          <AddText style={{ color: '#fff' }}>Success</AddText> // Display success message
        ) : imagesUploaded ? (
          <ButtonsContainer>
            <Button onClick={handleConfirm}>Confirm</Button>
            <Button cancel onClick={handleCancel}>
              Cancel
            </Button>
          </ButtonsContainer>
        ) : (
          <>
            <PlusIcon className="fa fa-plus" />
            <AddText>Click to add Images</AddText>
          </>
        )}
      </Container>
      <input
        type="file"
        accept="image/*"
        multiple
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />
    </>
  );
};

export default ProductImageUploader;