import React from "react";
import { styled, device } from "@ui";
import ProductShipment from "./ProductShipment/ProductShipment";
import ProductDisplayer from "./ProductViewer/ProductDisplayer";
import { useSearchParams } from "react-router-dom";
import { WEB_READ_PRODUCT_BY_ID } from "../controller/pmController";
import { useLazyQuery } from "@apollo/client";
import fallbackImage from "../docs/fallback.png"; // Import fallback image
import { imageService } from "src/store/config";
import ProductStoreInfo from "./ProductStore/ProductStoreInfo";
import ProductImageView from "./ProductViewer/ProductImageView";
import ProductDetailsInfo from "./ProductViewer/ProductDetailsInfo.v1";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  ${device.mobile`
    flex-direction: column;
  `}
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
   ${device.mobile`
    flex-direction: column;
  `}
`;

const DetailsSidebar = styled.div`
  display: flex;
  flex-direction: column;

  ${device.mobile`
    display: none;
  `}
`;

const ProductDisplayerContainer = styled("div")`
  display: flex;
  flex-direction: row;

  ${device.mobile`
    flex-direction: column;
  `}
`;

const ProductDetailsPage = () => {
  const [images, setImages] = React.useState<string[]>([]);

  // Get _id from URL parameters
  const [searchParams] = useSearchParams();
  const _id = searchParams.get("_id");

  // Lazy query to fetch product data
  const [readProduct, { data: productData, loading, error }] = useLazyQuery(WEB_READ_PRODUCT_BY_ID);

  React.useEffect(() => {
    if (_id) {
      readProduct({ variables: { _id } });
    }
  }, [_id]);

  React.useEffect(() => {
    if (productData?.public_product_read_productById) {
      const product = productData.public_product_read_productById;
      setImages(
        product.images?.length
          ? product.images.map((img: any) => `${imageService}/${img.filename}` || fallbackImage)
          : [fallbackImage] // Use fallback image if no images are provided
      );
    }
  }, [productData]);

  if (loading) return <div>Loading product details...</div>;
  if (error) return <div>Error fetching product details: {error.message}</div>;

  return (
    <Container>
        {/* Dynamic images */}
        <ProductDisplayerContainer>
          <ProductImageView rawImages={images} />
          <ProductDetailsInfo />
          <ProductShipment/>
        </ProductDisplayerContainer>
      <MainContent>
        <ProductStoreInfo
          avatarUrl={productData?.public_product_read_productById?.createdBy?.picture}
          storeName={productData?.public_product_read_productById?.createdBy?.firstName + " " + productData?.public_product_read_productById?.createdBy?.lastName}
          storeSells="1.3k sells"
          likes={2.4}
          dislikes={5}
          followers={5}
          productDescription={productData?.public_product_read_productById?.description}
        />
      <DetailsSidebar>
        
      </DetailsSidebar>
      </MainContent>
    </Container>
  );
};

export default ProductDetailsPage;