import React from 'react';
import styled from 'styled-components';

const StyledSelect = styled.select`
  padding: 10px;
  font-size: 16px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

interface SelectOptionProps {
  options: { label: string; value: string | number }[];
  value: any;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const SelectOption: React.FC<SelectOptionProps> = ({ options, value, onChange }) => (
  <StyledSelect value={value || ''} onChange={onChange}>
    <option value="" disabled>
      Select an option
    </option>
    {options.map((option) => (
      <option key={option.value.toString()} value={option.value}>
        {option.label}
      </option>
    ))}
  </StyledSelect>
);

export default SelectOption;