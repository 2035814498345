import { gql } from "@apollo/client";



/**
 * queries
 */

export const WEB_READ_PRODUCTS = gql`
    query public_product_read_Products{
        public_product_read_Products {
            _id title description barcode createdAt stock {quantity unit}
            price{value currency}categories{_id name description icon{filename extension}}images{filename extension _id isDefault}
        }
    }
`
export const WEB_READ_PRODUCT_BY_ID = gql`
    query public_product_read_productById($_id: ID!) {
        public_product_read_productById(_id: $_id) {
            _id title description barcode images{_id filename} 
            options{ name type values {label value} required }
            categories{_id name icon {filename extension}} 
            stock{quantity unit} price{value currency}
            createdBy{picture firstName lastName username}
            createdFrom{name icon views rating user_ratings_total}
        }
    }
`
export const ADMIN_READ_PRODUCTS = gql`
    query admin_product_read_Products{
        admin_product_read_Products {
            _id title description barcode createdAt stock {quantity unit}
            price{value currency}categories{_id name description icon{filename extension}}
        }
    }
`
export const SPACE_READ_PRODUCTS = gql`
    query space_product_read_Products {
        space_product_read_Products {
            _id title description barcode stock{quantity unit}
        }
    }
`
export const ADMIN_READ_PRODUCT_BY_ID = gql`
    query admin_product_read_productById($_id: ID!) {
        admin_product_read_productById(_id: $_id) {
            _id title description images{_id filename} barcode
            options{ name type values {label value} required }
            categories{_id name icon{filename extension}} stock{quantity unit} price{value currency}
        }
    }
`
export const ADMIN_PRODUCT_READ_CATEGORIES = gql`
    query admin_product_read_categories {
        admin_product_read_categories {
            _id name description 
            icon { filename extension }
        }
    }
`
/**
 * mutations
 */
/////////// admin
export const ADMIN_WRITE_PRODUCT = gql`
    mutation admin_product_write_Product($product: _Product){
        admin_product_write_Product(product: $product) {
            _id
        }
}`

export const ADMIN_UPDATE_PRODUCT = gql`
    mutation admin_product_update_Product($_id: ID!, $product: _Product){
        admin_product_update_product(_id: $_id, product: $product) {
            _id
        }
}`
///////////// space
export const SPACE_WRITE_PRODUCT_TITLE = gql`
    mutation space_product_write_ProductTitle($title: String){
        space_product_write_ProductTitle(title: $title){
            _id
        }
}`

export const DELETE_PRODUCT = gql`
    mutation delete_Product($_id: ID!) {
        delete_product(_id: $_id)
    }
`