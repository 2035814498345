import React from 'react';
import { device, styled } from '@ui';
import { Link } from 'react-router-dom';
import Commitment from './Commitment';


const ActionButton = styled("button")<{ primary?: boolean }>`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  color: white;
  background-color: ${(props) => (props.primary ? '#ff4b4b' : '#ffd1d1')};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
`;

const Actions = ({Label, isPrimary}:any) => (

    <ActionButton primary={isPrimary}><Link to="../cart">{Label}</Link></ActionButton>

);
////////////////////////////////////////
const ShippingSection = styled.div`
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

const ShippingText = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
`;

const DeliveryDate = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
`;

const Shipping = () => (
  <ShippingSection>
    <ShippingText>
      <span role="img" aria-label="truck">🚚</span> Free shipping
    </ShippingText>
    <DeliveryDate>Delivery: Aug 16 - 28</DeliveryDate>
  </ShippingSection>
);

///////////////////////////////////////
const SecuritySection = styled("div")`
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

const SecurityHeader = styled("div")`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const SecurityIcon = styled("span")`
  font-size: 18px;
  margin-right: 5px;
`;

const SecurityText = styled("div")`
  font-size: 14px;
`;


const Security = () => (
  <SecuritySection>
    <SecurityHeader>
      <SecurityIcon role="img" aria-label="lock">🔒</SecurityIcon>
      Security & Privacy
    </SecurityHeader>
    <SecurityText>
      Safe payments: We do not share your personal details with any third
      parties without your consent.
    </SecurityText>
    <SecurityText>
      Secure personal details: We protect your privacy and keep your
      personal details safe and secure.
    </SecurityText>
  </SecuritySection>
);
/////////////////////////////////////////
const QuantitySection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

const QuantityText = styled.div`
  font-size: 14px;
`;

const QuantityControls = styled.div`
  display: flex;
  align-items: center;
`;

const QuantityButton = styled.button`
  width: 25px;
  height: 25px;
  background-color: #eee;
  border: 1px solid #ddd;
  border-radius: 50%;
  font-size: 18px;
  line-height: 0;
  cursor: pointer;
  outline: none;
`;

const QuantityDisplay = styled.div`
  font-size: 14px;
  margin: 0 10px;
`;

const MaxQuantity = styled.div`
  font-size: 14px;
`;

const Quantity = () => (
  <QuantitySection>
    <QuantityText>Quantity</QuantityText>
    <QuantityControls>
      <QuantityButton>-</QuantityButton>
      <QuantityDisplay>1</QuantityDisplay>
      <QuantityButton>+</QuantityButton>
    </QuantityControls>
    <MaxQuantity>Max. 1 pcs/shopper</MaxQuantity>
  </QuantitySection>
);
///////////////////////////////////////////
const IconSection = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 10px;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const Icons = () => (
  <IconSection>
    <IconButton>
      <span role="img" aria-label="favorite">❤️</span>
    </IconButton>
    <IconButton>
      <span role="img" aria-label="share">🔗</span>
    </IconButton>
    <IconButton>
      <span role="img" aria-label="wishlist">🛒</span>
    </IconButton>
  </IconSection>
);


///////////////////////////////////
const ShipToSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

const ShipToText = styled.div`
  font-size: 14px;
`;

const ShipToCountry = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

const ShipTo = () => (
  <ShipToSection>
    <ShipToText>Ship to</ShipToText>
    <ShipToCountry>Morocco</ShipToCountry>
  </ShipToSection>
);

const Container = styled.div`
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  font-family: Arial, sans-serif;
  margin: 0 15px;
  text-align: left;

  ${device.mobile`
    border: 1px solid rgb(221, 221, 221);
    margin: 20px 5px 5px 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  `}
`;
const commitments: Commitment[] = [
  {
    policyName: "Free Returns",
    description: "Return your item within 15 days for a full refund.",
    duration: 15,
    isFree: true,
  },
  {
    policyName: "15-Day Trial",
    description: "Try the product for 15 days. If not satisfied, return for free.",
    duration: 15,
  },
  {
    policyName: "1-Year Warranty",
    description: "Covers manufacturer defects for one year.",
    duration: 365,
    isFree: false,
  },
]
const ProductShipment = () => (
  <Container>
    <ShipTo />
    <Commitment commitments={commitments}/>
    <Shipping />
    <Security />
    <Quantity />
    <Actions />
    <Icons />
  </Container>
);

export default ProductShipment;
