import * as React from "react";
import {
  WrapperContent,
  WrapperFooter,
  WrapperWebMain,
  WrapperWeb,
  Header,
  Subheader,
  WebSidebar,
  WebRightbar,
  GlobalStyles,
} from "@ui";
import { Route, Routes } from "react-router-dom";

/**
 * Core folder modules
 */
const req = require.context("src/core", true, /\/web\/.*Dash.*\.tsx?$/);

const modules = req
  .keys()
  .map((key: any) => ({ dash: req(key).default, path: req(key).ROUTE_BASE }));

/**
 * 
 * Extensions folder modules
 */
const reqExt = require.context("src/extensions", true, /\/web\/.*Dash.*\.tsx?$/);

const extensions = reqExt
  .keys()
  .map((key: any) => ({ dash: reqExt(key).default, path: reqExt(key).ROUTE_BASE }));


export const Home: React.FC<any> = () => {
  return (
    <>
      <GlobalStyles />
      <WrapperWeb>
        <Header />

        <Subheader />

        <WrapperWebMain>
          <WrapperContent>
            
            <Routes>
              {modules.map((module: any) => (
                <Route
                  key={module.path}
                  path={module.path + "/*"}
                  element={<module.dash />}
                />
              ))}
              {extensions.map((module: any) => (
                <Route
                  key={module.path}
                  path={module.path + "/*"}
                  element={<module.dash />}
                />
              ))}
            </Routes>
          </WrapperContent>

          <WebRightbar />
        </WrapperWebMain>

        {/* <WrapperFooter>
          <div style={{ display: "flex" }}>
            <i
              className="fab fa-whatsapp"
              style={{ fontSize: "38px", color: "green", padding: "0 5px" }}
            />
            <a href="https://wa.me/212708239690">00212708239690</a>
          </div>
        </WrapperFooter> */}
      </WrapperWeb>
    </>
  );
};
