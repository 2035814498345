import * as React from "react";
import { Input } from "../../../ui-ittyni";
import { useTranslation } from "react-i18next";
import { SpaceFinder } from "./SpaceFinder";

export const SpaceIdentification: React.FC<any> = ({
  spaceInformation,
  setSpaceInformation,
}) => {
  const [SpaceFinderModal, openSpaceFinder] = React.useState<boolean>(false);
  const [spaceSelected, setSpaceSelected] = React.useState<any>();
  const { t } = useTranslation();

  const handleChange = ({ target }: any) =>
    setSpaceSelected({
      ...spaceSelected,
      account: { [target.name]: target.value },
    });

  const clearData = () => {
    setSpaceSelected(undefined);
  };

  React.useEffect(() => {
    setSpaceInformation({ ...spaceInformation, space: spaceSelected });
  }, [spaceSelected]);

  return (
    <fieldset
      style={{ display: "flex", flexDirection: "column", marginBottom: "15px" }}
    >
      <legend>IDENTIFICATION</legend>
      <div>
        <label>{t("space.identification.name")} :</label>
        <Input
          border="1px solid lightgrey"
          width="50%"
          name="name"
          onChange={handleChange}
          value={spaceSelected?.name || ""}
        />
        {spaceSelected && (
          <button onClick={() => clearData()}>
            <i className="fas fa-times-circle" />
          </button>
        )}
        {!spaceSelected && (
          <button onClick={() => openSpaceFinder(!SpaceFinderModal)}>
            <i className="fas fa-search" />
          </button>
        )}
      </div>

      {/* Display the first photo if available */}
      {spaceSelected?.photo && (
        <div>
          <img
            src={spaceSelected?.photo}
            height={200}
            width={200}
            alt="Space"
          />
        </div>
      )}

      {SpaceFinderModal && (
        <SpaceFinder
          showModal={SpaceFinderModal}
          setShowModal={openSpaceFinder}
          onSelectSpace={(space: any) => {
            // Extract all photo URLs from html_attributions
            setSpaceSelected({
              photos: space.photos?.map((p: any)=>p.getUrl({ maxWidth: 250, maxHeight: 250 })), // Store all extracted photo URLs
              name: space.name,
              icon: space.icon,
              rating: space.rating,
              user_ratings_total: space.user_ratings_total,
              reference: space.place_id,
              location: {
                latitude: space.geometry.location.lat,
                longitude: space.geometry.location.lng,
              },
            });
            openSpaceFinder(false);
          }}
        />
      )}
    </fieldset>
  );
};
