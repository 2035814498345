import { useQuery } from "@apollo/client";
import { ADMIN_PRODUCT_READ_CATEGORIES } from "@extensions/ittyni_products/controller/pmController";
import { Search } from "@ui";
import * as React from "react";
import { imageService, uploadService } from "src/store/config";
import styled from "styled-components";


const Container = styled('div')`
  padding: 20px;
`;

const Title = styled('h1')`
  margin-bottom: 20px;
`;

const CategoryList = styled('ul')`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
`;

const CategoryItem = styled('li') <{ isSelected: boolean }>`
  box-shadow: 0 0 10px 0 ${({ isSelected }) => (isSelected ? '#ff9800' : '#888888')};
  border-radius: 30px;
  padding: 16px;
  width: 120px;
  margin: 10px;
  cursor: pointer;
  background-color: ${({ isSelected }) => (isSelected ? '#ffe0b2' : '#ffffff')};
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
  }
`;

const CategoryIcon = styled('div')`
  background-size: contain;
  background-repeat: no-repeat;
  height: 150px;
`;

const CategoryName = styled('div')`
  font-size: 21px;
  font-weight: bold;
  font-family: cursive;
`;

const CategoryDescription = styled('div')`
  margin-top: 10px;
`;

interface Category {
  _id: string;
  name: string;
  icon: { filename: string; extension: string }; // Updated icon structure
}

export const ProductCategoriesModal: React.FC<any> = ({
  selectedCategories,
  onSelectCategories
}) => {
  const { data, loading } = useQuery(ADMIN_PRODUCT_READ_CATEGORIES);
  const [searchTerm, setSearchTerm] = React.useState(''); // State to store search input
  const [localSelection, setLocalSelection] = React.useState<Category[]>(selectedCategories);

  React.useEffect(() => {
    if (localSelection !== selectedCategories) {
      onSelectCategories(localSelection); // Only update if necessary
    }
  }, [localSelection, onSelectCategories]);

  React.useEffect(() => {
    if (selectedCategories !== localSelection) {
      setLocalSelection(selectedCategories); // Avoid redundant updates
    }
  }, [selectedCategories])

  // Filter categories based on the search input
  const filteredCategories = React.useMemo(() => {
    if (!data?.admin_product_read_categories) return [];
    return data.admin_product_read_categories.filter((category: any) =>
      category.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [data, searchTerm]);

  if (loading) return <div>Loading...</div>; // Show loading state

  const toggleCategory = (category: Category) => {
    setLocalSelection((prev) => {
      const exists = prev?.some((cat) => cat._id === category._id);
      return exists
        ? prev.filter((cat) => cat._id !== category._id) // Remove from selection if already selected
        : [...prev, category]; // Add to selection if not already selected
    });
  };

  return (
    <Container>
      <Search onSearch={(value: string) => setSearchTerm(value)} placeholder="Search categories..." />
      <CategoryList>
        {filteredCategories.map((category: any) => (
          <CategoryItem
            key={category._id}
            isSelected={localSelection.some((cat) => cat._id === category._id)} // Check if selected
            onClick={() => toggleCategory(category)}
          >
            <CategoryIcon
              style={{
                backgroundImage: `url("${imageService}/${category.icon?.filename}")`,
              }}
            />
            <CategoryName>{category.name}</CategoryName>
            <CategoryDescription>{category.description}</CategoryDescription>
          </CategoryItem>
        ))}
      </CategoryList>
    </Container>
  );
};