import * as React from "react";
import { Link, Route, Routes } from "react-router-dom";
import { Breadcrumbs, Bubble } from "../../../ui-ittyni";
import FileUpload from "../components/FileUpload";
import ProductsManager from "../components/ProductList/productList.v2/ProductsManager.v1.4";
import AdminProductDetails from "./AdminProductDetails";

export const ROUTE_BASE = "product";

const AdminProductDash: React.FC<any> = () => {
  return (
    <div style={{ width: "100%" }}>
      <Breadcrumbs />
      <h1
        style={{
          fontFeatureSettings: "liga 0",
          fontVariantLigatures: "no-contextual",
          fontFamily: "Google Sans ,Arial,sans-serif",
          fontSize: "1.375rem",
          fontWeight: 400,
          lineHeight: "1.75rem",
        }}
      ></h1>
      <div style={{ display: "flex", border: "0.5px solid lightgrey" }}>
        <Bubble>
          <Link to={`allProducts`}>all product</Link>
        </Bubble>
        <Bubble>
          <Link to={`addProduct`}>add product</Link>
        </Bubble>
        <Bubble>
          <Link to={`upload`}>upload image</Link>
        </Bubble>
      </div>
      <Routes>
        <Route path={`upload`} element={<FileUpload />} />
        <Route path={`addProduct`} element={<AdminProductDetails />} />
        <Route path={`allProducts`} element={<ProductsManager />} />

        <Route
          path="/"
          element={
            <>
              <h2 onClick={() => console.log("clicked")}>user name</h2>

              <h4>Demarrer</h4>
              <ul style={{ listStyle: "none" }}>
                <li style={{ padding: "0 5px 5px" }}>
                  <Link to={`addProduct`}>
                    <i className="fas fa-file" />
                    Ajouter un Produit
                  </Link>
                </li>

                <li style={{ padding: "0 5px 5px" }}>
                  <Link to={"allProducts"}>
                    <i className="fas fa-list" aria-hidden="true" />
                    List All Products
                  </Link>
                </li>
              </ul>
            </>
          }
        />
      </Routes>
    </div>
  );
};

export default AdminProductDash;
