import React from 'react';
import styled from 'styled-components';

const StyledTextInput = styled.input`
  padding: 10px;
  font-size: 16px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

interface TextInputProps {
  type: 'text' | 'number';
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
}

const TextInput: React.FC<TextInputProps> = ({
  type,
  value,
  onChange,
  placeholder,
}) => <StyledTextInput type={type} value={value} onChange={onChange} placeholder={placeholder} />;

export default TextInput;
