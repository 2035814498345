import * as React from "react";
import ProductCard from "./ProductCards/ProductCard";
import ProductFilters from "./ProductFilters/ProductFilters";
import { useQuery } from "@apollo/client";
import { WEB_READ_PRODUCTS } from "../controller/pmController";
import fallbackImage from "../docs/fallback.png"; // Import fallback image
import { imageService } from "src/store/config";

export const WebProductList = () => {
  const { data, loading, error } = useQuery(WEB_READ_PRODUCTS);

  if (loading) {
    return <div>Loading products...</div>;
  }

  if (error) {
    return <div>Error loading products: {error.message}</div>;
  }

  return (
    <div style={{ display: "flex" }}>
      <ProductFilters />
      <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
        {/* Products from API */}
        {data?.public_product_read_Products?.map((product: any) => (
          <ProductCard
            key={product._id} // Ensure unique key
            _id={product._id}
            images={
              product.images?.length
                ? product.images.map((img: any) => `${imageService}/${img.filename}`)
                : [fallbackImage] 
            }
            name={product.title}
            price={`${product.price?.value ?? ""} ${
              product.price?.currency ?? "MAD"
            }`}
            rank={product.rank ?? 0}
            sales={product.sales ?? 0}
            ratingCount={product.ratingCount ?? 0}
          />
        ))}
      </div>
    </div>
  );
};