import * as React from "react";
import { styled, device } from "@ui";
import ProductDescription from "../ProductViewer/ProductDescription";
import Actions from "../ProductViewer/ProductActions";

// Define the props interface
interface ProductInfoProps {
  storeName: string;
  storeSells: string;
  avatarUrl: string;
  productDescription: string;
  likes: number;
  dislikes: number;
  followers: number;
}

const StoreInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;

  ${device.mobile`
    flex-direction: column;
    align-items: flex-start;
  `}
`;

const AvatarContainer = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #e1e1e1;
  margin-right: 15px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;

const StoreDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;

  .storeName {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .storeSells {
    font-size: 16px;
    font-weight: bold;
    color: #007bff;
    margin-bottom: 5px;
  }

  .followers {
    font-size: 14px;
    color: #666;
  }
`;

const ProductInfoContainer = styled.div`
  padding: 20px;
  width: 74%;
  ${device.mobile`
    padding: 10px;
    width: 98%;
    padding: 0 2px;
  `}
`;

// Subcomponents
const Avatar: React.FC<{ avatarUrl: string }> = ({ avatarUrl }) => (
  <AvatarContainer>
    <img src={avatarUrl} alt="Store Avatar" />
  </AvatarContainer>
);

const StoreDetails: React.FC<{
  storeName: string;
  storeSells: string;
  followers: number;
}> = ({ storeName, storeSells, followers }) => (
  <StoreDetailsContainer>
    <div className="storeName">{storeName}</div>
    <div className="storeSells">{storeSells}</div>
    <div className="followers">{followers} followers</div>
  </StoreDetailsContainer>
);

const ProductStoreInfo: React.FC<ProductInfoProps> = ({
  storeName,
  storeSells,
  avatarUrl,
  productDescription,
  likes,
  dislikes,
  followers,
}) => {
  return (
    <ProductInfoContainer>
      {/* Store Info Section */}
      <StoreInfo>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar avatarUrl={avatarUrl} />
          <StoreDetails
            storeName={storeName}
            storeSells={storeSells}
            followers={followers}
          />
        </div>
        {/* Actions Section */}
        <Actions />
      </StoreInfo>

      {/* Product Description */}
      <ProductDescription description={productDescription} />
    </ProductInfoContainer>
  );
};

export default ProductStoreInfo;