import React from "react";
import styled from "styled-components";
import ProductOptionsDisplayer from "./ProductOptionsDisplayer";
import { useSearchParams } from "react-router-dom";

const ColorSection = styled.div`
  padding: 10px;
  text-align: left;
`;




const ProductOptions: React.FC<any> = ({opt}: any) => {
  const [options, setOptions] = React.useState(opt || [])
  const [searchParams] = useSearchParams();
  const _id = searchParams.get('_id');

  React.useEffect(()=>{
    if(opt){
      setOptions(opt)
    }
  }, [opt])
  
  return (

    <ColorSection>
      <ProductOptionsDisplayer options={options} onOptionChange={(c: string, v: any) => console.log(c, v)} />
    </ColorSection>

  )
}

export default ProductOptions;